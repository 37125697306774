module.exports = {
  "jackets": {
    "src": [
      "urlHostPathNameOnly1",
      "urlHostPathNameOnly2"
    ],
    "id": [
      "jacket1",
      "jacket2"
    ]
  },

  "shoes": {
    "src": [
      "urlHostPathNameOnly1",
      "urlHostPathNameOnly2"
    ],
    "id": [
      "shoes1",
      "shoes2"
    ]
  }
}
;